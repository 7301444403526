export default {
  visualized: {
    sample: {
      Sample_ID: 'Sample_ID',
      Sample_Name: 'Sample_Nam',
      Continent_or_Ocean: 'Continent_', // 大陆或大洋
      Country: 'Country', // 国家
      Terrane: 'Terrane', // 地体
      Rock_or_stratigraphic_unit: 'Rock_or_st', // 岩石或地层单元
      Object_or_mineral_determined: 'Object_or_', // 测定对象或矿物
      Geodynamic_settings: 'Geodynamic', // 地球动力学背景
      Geological_period: 'Geological', // 地质时代
      Min_age_limit: 'Min_age_li', // 地质时代最小值
      Max_age_limit: 'Max_age_li', // 地质时代最大值
      Lithology: 'Lithology', // 岩性
      Metamorphic_grade: 'Metamorphi', // 变质级别
      Reference_type: 'Reference_', // 文献类型
      Language: 'Language', // 语言
      Journal: 'Journal', // 刊物名
      Title: 'Title', // 题名
      First_Author: 'First_Auth', // 第一作者名
      Year: 'Year', // 出版年
      Place_for_experiment: 'Place_for_', // 测试机构
      Instrument_for_analysis: 'Instrument', // 测试仪器
      Analytical_content: 'Analytical', // 测试内容
      Expression: 'Expression', // 表达方式
      Interpretation: 'Interpreta', // 年龄解释
      Age: 'Age', // 年龄值
      SE2: 'SE2', // 误差2SE(Ma)
    }
  }
}
