<template>
  <!-- 可视化查询结果页 -->
  <div class="m-pg-sub">
    <page-cover code="5" layer="rgba(0,0,0,.5)">
      <!-- <h2 class="m-page-title">{{$t('page.result.name')}}</h2>
      <el-breadcrumb class="m-breadcrumb m-box-shadow" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('page.home.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/search' }">{{$t('page.search.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('page.result.name')}}</el-breadcrumb-item>
      </el-breadcrumb> -->
    </page-cover>
    <div class="m-pg-sub_main m-w1200">
      <!-- <el-form class="filter" :inline="true">
        <el-button type="primary" icon="el-icon-download">{{$t('action.download')}}</el-button>
      </el-form> -->
      <el-table
        class="tb-data el-table--custom"
        v-loading="table.loading"
        :data="table.data"
        size="medium"
        stripe
        tooltip-effect="light">
        <el-table-column
          fixed="left"
          type="selection"
          header-align="center"
          align="center">
        </el-table-column>

        <!-- 样品号 -->
        <el-table-column
          :prop="`properties[${mapping.visualized.sample['Sample_Name']}]`"
          :label="$t('model.sample.Sample_Name')"
          min-width="180"
          show-overflow-tooltip>
          <!-- <template scope="{row}">
            {{row.properties.Sample_Nam}}
          </template> -->
        </el-table-column>

        <!-- 大洲或大洋 -->
        <el-table-column
          :prop="`properties[${mapping.visualized.sample['Continent_or_Ocean']}]`"
          :label="$t('model.sample.Continent_or_Ocean')"
          min-width="180"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 国家 -->
        <el-table-column
          :prop="`properties[${mapping.visualized.sample['Country']}]`"
          min-width="180"
          :label="$t('model.sample.Country')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 地体 -->
        <el-table-column
          :prop="`properties[${mapping.visualized.sample['Terrane']}]`"
          min-width="180"
          :label="$t('model.sample.Terrane')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 地球动力学背景 -->
        <el-table-column
          :prop="`properties[${mapping.visualized.sample['Geodynamic_settings']}]`"
          min-width="180"
          :label="$t('model.sample.Geodynamic_settings')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 岩石或底层单元 -->
        <el-table-column
          :prop="`properties[${mapping.visualized.sample['Rock_or_stratigraphic_unit']}]`"
          min-width="190"
          :label="$t('model.sample.Rock_or_stratigraphic_unit')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 地质时代 -->
        <el-table-column
          :prop="`properties[${mapping.visualized.sample['Geological_period']}]`"
          min-width="180"
          :label="$t('model.sample.Geological_period')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 岩性 -->
        <el-table-column
          :prop="`properties[${mapping.visualized.sample['Lithology']}]`"
          min-width="180"
          :label="$t('model.sample.Lithology')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 变质级别 -->
        <el-table-column
          :prop="`properties[${mapping.visualized.sample['Metamorphic_grade']}]`"
          min-width="180"
          :label="$t('model.sample.Metamorphic_grade')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 测定对象或矿物 -->
        <el-table-column
          :prop="`properties[${mapping.visualized.sample['Object_or_mineral_determined']}]`"
          min-width="214"
          :label="$t('model.sample.Object_or_mineral_determined')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 刊物名 -->
        <el-table-column
          :prop="`properties[${mapping.visualized.sample['Journal']}]`"
          min-width="180"
          :label="$t('model.sample.Journal')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 题名 -->
        <el-table-column
          :prop="`properties[${mapping.visualized.sample['Title']}]`"
          min-width="180"
          :label="$t('model.sample.Title')"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          :prop="`properties[${mapping.visualized.sample['Year']}]`"
          min-width="180"
          :label="$t('model.sample.Year')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 第一作者姓名 -->
        <el-table-column
          :prop="`properties[${mapping.visualized.sample['First_Author']}]`"
          min-width="180"
          :label="$t('model.sample.First_Author')"
          show-overflow-tooltip>
        </el-table-column>


        <!-- 表达方式 -->
        <el-table-column
          :prop="`properties[${mapping.visualized.sample['Expression']}]`"
          min-width="180"
          :label="$t('model.sample.Expression')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 年龄值(Ma) -->
        <el-table-column
          :prop="`properties[${mapping.visualized.sample['Age']}]`"
          min-width="180"
          :label="$t('model.sample.Age')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 误差2SE(Ma) -->
        <el-table-column
          :prop="`properties[${mapping.visualized.sample['SE2']}]`"
          min-width="180"
          :label="$t('model.sample.SE2')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 年龄解释 -->
        <el-table-column
          :prop="`properties[${mapping.visualized.sample['Interpretation']}]`"
          min-width="180"
          :label="$t('model.sample.Interpretation')"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          min-width="140"
          :label="$t('word.operation')"
          show-overflow-tooltip>
          <template slot-scope="{ row }">
            <el-button :title="$t('action.detail')" size="mini" type="primary" icon="el-icon-document" @click="onDetail(row)"></el-button>
            <el-button :title="$t('page.result.action.mark')" size="mini" type="primary" icon="el-icon-location" @click="onMark(row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pager"
        background
        layout="prev, pager, next"
        :page-size.sync="page.size"
        :current-page.sync="page.index"
        :total="page.total"
        @current-change="onPageChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import setting from '@/setting'
import { param } from '@/utils'
import mapping from '@/config/mapping'
import visualizedApi from '@/api/visualized'
export default {
  name: 'Search_Result',
  components: {
  },
  data() {
    return {
      mapping,
      features: [], // 返回的所有数据 前端分页
      table: {
        loading: false,
        data: []
      },
      page: {
        index: 1,
        size: 20,
        total: 0
      }
    }
  },
  methods: {
    getData() {
      this.table.loading = true
      let qs = param(this.$route.query)
      if (qs) {
        visualizedApi.getSample(qs)
        .then(res => {
          this.table.loading = false
          if (!res || !res.data || !res.data.features) {return}
          this.features = res.data.features
          this.page.total = this.features.length
          this.pagination()
        }).catch(err => {
          this.table.loading = false
        })
      }
    },
    // 前端分页
    pagination() {
      let offset = (this.page.index - 1) * this.page.size
      let data = this.features
      this.table.data = (offset + this.page.size >= data.length) ? data.slice(offset, data.length) : data.slice(offset, offset + this.page.size)
    },
    onDetail(item) {
      this.$router.push(`/search/result-detail?sampleId=${item.properties.Sample_ID}`)
    },
    onMark(item) {
      window.open(`${setting.visualized.webUrl}?mode=label&sampleID=${item.properties.Sample_ID}`, '_blank')
    },
    onPageChange(val) {
      this.pagination()
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
  .m-pg-sub_main {
    margin: 20px auto;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
    .filter {
      padding: 12px 16px;
      text-align: right;
      /deep/ .el-input {
        width: 193px;
      }
    }
    .pager {
      padding: 16px 10px;
    }
  }
</style>
