/**
 * 可视化空间查询
 */
import axios from 'axios'
import setting from '@/setting'

let baseUrl = setting.visualized.apiBaseUrl
// 获取样品列表
export function getSample(qs) {
  return axios({
    url: `${baseUrl}geoserver/zkyxm/wms/ows?${qs}`
  })
}

export default {
  getSample
}
